import "./MessageschatPage.css";
import React, { useState, useEffect } from "react";
import { getUploadimageapiRequest } from "../util/uploadImage";
import { getSendMsgnotificationapiRequest } from "../util/sendMsgNotification";
import { xml } from "@xmpp/client";
import profile_default from "../../assets/profile_default.png";
import GroupdetailmodalPage from "../../messages/components/GroupdetailmodalPage";
import $ from "jquery";

function GroupChat(props) {
  const {
    groupnameimg,
    xmppclient,
    accessToken,
    showgroupMessage,
    loginId,
    getGrouplist,
  } = props;

  const [showGroupdetail, setShowgroupdetail] = useState(false);

  const [groupMessage, setGroupMessage] = useState("");

  const groupJid = window.location.pathname.split("/").pop();

  useEffect(() => {
    $("#groupdiv").scrollTop($("#groupdiv")[0].scrollHeight);
  }, [showgroupMessage]);

  const groupMessageInputchanged = (e) => {
    setGroupMessage(e.target.value);
  };

  const sendMessagetogroup = () => {
    if (groupMessage !== "") {
      sendMessagetogroups(groupMessage);
    }
  };

  const getAttachmentfile = async (e) => {
    let getAttachment = document.getElementById("groupattachment").files[0];

    const getUploadResponse = await getUploadimageapiRequest(
      accessToken,
      getAttachment
    );

    if (getUploadResponse.status === 200) {
      let getFile = await getUploadResponse.json();
      sendMessagetogroups(getFile?.image_url);
    }

    e.target.value = "";
  };

  const sendMessagetogroups = async (groupMessage) => {
    let uid = Date.now();

    const message = xml(
      "message",
      {
        xmlns: "jabber:client",
        id: uid,
        to: groupJid,
        type: "groupchat",
      },
      xml("body", {}, groupMessage),
      xml("request", {
        xmlns: "urn:xmpp:receipts",
      })
    );

    await xmppclient.send(message);

    // Check send message is it image video or text
    setGroupMessage("");

    // After send msg call the sendmsgnotification api
    await getSendMsgnotificationapiRequest(groupJid, groupMessage, accessToken);
  };

  const displayGroupdetail = () => {
    setShowgroupdetail(true);
  };

  return (
    <>
      <div className="col-sm-12 col-md-8 col-lg-8 col-xl-9 mesgs">
        <div className="contact-profile">
          <img
            src={groupnameimg.groupImage}
            onError={(event) => {
              event.target.src = profile_default;
            }}
            alt=""
          />
          <p>{groupnameimg.groupName}</p>
          <i
            className="bi-info-circle-fill detail-icon"
            title="Group detail"
            onClick={() => displayGroupdetail()}
          />
        </div>
        <div className="msg_history" id="groupdiv">
          {showgroupMessage.map((item) =>
            item.type === "receive" ? (
              <>
                <div className="incoming_msg">
                  <div className="received_msg">
                    <div className="received_withd_msg">
                      {item.show === "text" ? (
                        <p>{item.message}</p>
                      ) : item.show === "document" ? (
                        <a href onClick={() => window.open(item.message)}>
                          <img
                            onError={(event) => {
                              event.target.src = item.default_photo;
                            }}
                            className="received_img"
                            alt=""
                            src={item.source}
                          ></img>
                        </a>
                      ) : (
                        // <img className="received_img" alt="" src={video_icon}></img>
                        <video className="received_video" controls>
                          <source src={item.message} type="video/mp4" />
                          <source src={item.message} type="video/ogg" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="outgoing_msg">
                  <div className="sent_msg">
                    {item.show === "text" ? (
                      <p>{item.message}</p>
                    ) : item.show === "document" ? (
                      <a href onClick={() => window.open(item.message)}>
                        <img
                          className="send_img"
                          alt=""
                          onError={(event) => {
                            event.target.src = item.default_photo;
                          }}
                          src={item.source}
                        ></img>
                      </a>
                    ) : (
                      <video className="send_video" controls>
                        <source src={item.message} type="video/mp4" />
                        <source src={item.message} type="video/ogg" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </div>
                </div>
              </>
            )
          )}
        </div>
        <div className="type_msg">
          <div className="input_msg_write">
            <input
              type="text"
              classNameName="write_msg"
              value={groupMessage}
              onChange={groupMessageInputchanged}
              placeholder="Type a message"
            />
            <button
              className="msg_send_btn"
              type="button"
              onClick={() => sendMessagetogroup()}
            >
              <i className="fa fa-paper-plane" aria-hidden="true"></i>
            </button>
            <input
              type="file"
              accept="image/*,video/*"
              id="groupattachment"
              style={{ display: "none" }}
              onChange={getAttachmentfile}
            />
            <button
              className="btn-attach"
              type="button"
              onClick={() => document.getElementById("groupattachment").click()}
            >
              <i className="fa fa-paperclip" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <GroupdetailmodalPage
        accessToken={accessToken}
        showGroupdetail={showGroupdetail}
        setShowgroupdetail={setShowgroupdetail}
        groupnameimg={groupnameimg}
        loginId={loginId}
        getGrouplist={getGrouplist}
      />
    </>
  );
}

export default GroupChat;
