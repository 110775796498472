import React, { useState } from "react";
import "./GroupdetailmodalPage.css";
import { getGroupDetail } from "../util/getGroupDetail";
import { deleteGroupData } from "../util/deleteGroup";
import { removeFromGroup } from "../util/removeFromGroup";
import NewmemberlistmodalPage from "../components/NewmemberlistmodalPage";
import { Modal } from "react-bootstrap";
import "./MessageschatPage.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function GroupdetailmodalPage(props) {
  const {
    showGroupdetail,
    setShowgroupdetail,
    groupnameimg,
    accessToken,
    loginId,
    getGrouplist,
  } = props;

  const [groupDetail, setGroupdetail] = useState();

  const [openlist, setOpenlist] = useState(false);

  const [loginIdmatch, setLoginidmatch] = useState(false);

  const openGroupdetail = async () => {
    setShowgroupdetail(true);
    callGroupdetail();
  };

  const callGroupdetail = async () => {
    let groupDetail = await getGroupDetail(groupnameimg?.groupId, accessToken);
    if (groupDetail.status === 200) {
      let getres = await groupDetail.json();
      let getId = getres?.members?.filter((item) => item?.id === loginId);
      if (getId?.length !== 0) {
        setLoginidmatch(true);
      } else {
        setLoginidmatch(false);
      }
      setGroupdetail(getres);
    }
  };

  const closeGroupdetail = () => {
    setShowgroupdetail(false);
  };

  const groupDelete = async () => {
    let data = await deleteGroupData(groupnameimg.groupId, accessToken);
    if (data.status === 200) {
      toast.success("Group deleted successfully");
      setShowgroupdetail(false);
      getGrouplist(accessToken, true);
    }
  };

  const removeMemberfromgroup = async (id) => {
    let data = await removeFromGroup(id, groupnameimg?.groupId, accessToken);
    if (data.status === 200) {
      toast.success("Member removed successfully");
      callGroupdetail();
    }
  };

  const openMemberlist = () => {
    setOpenlist(true);
  };

  const leftfromGroup = async () => {
    let data = await removeFromGroup(
      loginId,
      groupnameimg?.groupId,
      accessToken
    );
    if (data.status === 200) {
      toast.success("Left from group Successfully");
      setShowgroupdetail(false);
      getGrouplist(accessToken, true);
    }
  };

  return (
    <>
      <Modal
        show={showGroupdetail}
        onShow={() => openGroupdetail()}
        onHide={() => closeGroupdetail()}
        centered
        className="group-detail"
      >
        <Modal.Header closeButton>
          <Modal.Title>Group detail</Modal.Title>
          {groupDetail?.group_admin !== true && loginIdmatch === true ? (
            <div className="group-left" onClick={() => leftfromGroup()}>
              <i class="fa fa-sign-out" title="Left from group"></i>
            </div>
          ) : (
            <div
              className="group-delete"
              onClick={(e) =>
                window.confirm("Do you really want to delete this group ?") &&
                groupDelete()
              }
            >
              <i className="material-icons" title="Delete group">
                &#xE872;
              </i>
            </div>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="basic-detail">
            <h5>
              Group name : <span>{groupDetail?.name}</span>
            </h5>
            <h5>
              Total Members : <span>{groupDetail?.count}</span>
            </h5>
            <h5>
              Description : <span>{groupDetail?.description}</span>
            </h5>
          </div>

          <div className="table-responsive">
            <div className="table-wrapper">
              <div className="table-title">
                <div className="row">
                  <div className="col-sm-8">
                    <h2>Members :</h2>
                  </div>
                  {groupDetail?.group_admin === true && (
                    <div class="col-sm-4">
                      <button class="add-new" onClick={() => openMemberlist()}>
                        <i class="fa fa-plus"></i> Add New
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <table className="table">
                <thead>
                  <tr>
                    <th>First name</th>
                    <th>Last name</th>
                    {groupDetail?.group_admin === true ? (
                      <th>Remove</th>
                    ) : (
                      <th></th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {groupDetail?.members.map((item) => (
                    <tr>
                      <td>{item?.first_name}</td>
                      <td>{item?.last_name}</td>
                      {groupDetail?.group_admin === true &&
                      item?.id !== loginId ? (
                        <td className="delete-member">
                          <a
                            className="delete"
                            title="Remove member from group"
                            data-toggle="tooltip"
                            onClick={(e) =>
                              window.confirm(
                                "Do you want remove this member from group?"
                              ) && removeMemberfromgroup(item?.id)
                            }
                            href
                          >
                            <i className="material-icons">&#xE872;</i>
                          </a>
                        </td>
                      ) : (
                        <td className="delete-member"></td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <NewmemberlistmodalPage
        openlist={openlist}
        setOpenlist={setOpenlist}
        groupDetail={groupDetail}
        accessToken={accessToken}
        groupnameimg={groupnameimg}
        setShowgroupdetail={setShowgroupdetail}
      />
    </>
  );
}
export default GroupdetailmodalPage;
