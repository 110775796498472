export const getGroupDetail = async (id, access_token) => {
  try {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    const callGroupdetail = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/groupDetail?id=${id}&api_token=${access_token}`,
      {
        headers,
        method: "GET",
      }
    );
    return callGroupdetail;
  } catch (error) {}
};
