export const getaddMemberapiRequest = async (group_jid, ids) => {
  try {
    const headers = new Headers();

    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

    const calladdMembertogroup = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/addMemberToGroup?group_jid=${group_jid}&ids=${ids}`,
      {
        headers,
        method: "POST",
      }
    );

    return calladdMembertogroup;
  } catch (error) {}
};
