import "./MessageschatPage.css";
import search_icon from "../../assets/search-icon.png";
import { getLoginapiRequest } from "../util/login";
import { getReadMsgnotificationapiRequest } from "../util/readMsgNotification";
import { useEffect, useRef, useState } from "react";
import { client, xml } from "@xmpp/client";
import debug from "@xmpp/debug";
import { getmyChatListapiRequet } from "../util/myChatList";
import { getGroupChatListapiRequet } from "../util/groupChatList";
import ChatPage from "../components/ChatPage";
import GroupChat from "../components/GroupChat";
import profile_default from "../../assets/profile_default.png";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Tabs, Tab } from "react-bootstrap";
import doc_doc from "../../assets/doc_doc.png";
import excel_doc from "../../assets/excel_doc.png";
import pdf_doc from "../../assets/pdf_doc.png";
import ppt_doc from "../../assets/ppt_doc.png";
import jpg_icon from "../../assets/jpg_icon.svg";

function MessageschatPage() {
  const [xmppclient, setXmppclient] = useState(undefined);

  const [accessToken, setAccesstoken] = useState("");

  const [userinfo, setUserinfo] = useState([]);

  const [groupinfo, setGroupinfo] = useState([]);

  const [searchlabel, setSearchlabel] = useState("");

  const [recerverJid, setRecerverjid] = useState("");

  const [showallmessages, setShowallmessages] = useState([]);

  const [showChat, setShowchat] = useState(false);

  let getMessagehistoryarry = [];

  // const [loginJid, setLoginjid] = useState("");
  const [loginId, setLoginId] = useState("");
  const [currentTab, setCurrenttab] = useState("direct");

  // Group state
  const [showGroups, setShowGroups] = useState(false);
  const [showgroupMessage, setShowgroupmessage] = useState([]);

  let getGrouphistoryarry = [];

  const userNameimage = {
    userName: "",
    userImage: "",
  };

  const groupNameimage = {
    groupName: "",
    groupImage: "",
    groupId: "",
  };

  const [usernameimg, setUsernameImage] = useState(userNameimage);

  const [groupnameimg, setGroupNameImage] = useState(groupNameimage);

  const ref = useRef(Math.random());

  let getjidFromurl = window.location.pathname.split("/").pop();

  let url = window.location.pathname.split("/");

  let getUserdetailarray = [];

  let getGroupdetailarray = []; // another group array

  let getValue = {};

  const navigate = useNavigate();

  // eslint-disable-next-line
  useEffect(async () => {
    setAccesstoken(url[2]); // store access token

    let getLoginRes = await getLoginapiRequest(url[2]);

    if (getLoginRes.status === 200) {
      let getRes = await getLoginRes.json();

      if (url[1] === "groups") {
        groupRefresh(url[2], false); // When we refresh the page from group
      } else {
        getUserlist(url[2], false);
      }

      localStorage.setItem("loginJid", getRes?.data?.jid); // store current login jid
      setLoginId(getRes?.data?.id); // store current login id

      const xmpp = client({
        service: process.env.REACT_APP_SERVICE_URL,
        domain: process.env.REACT_APP_DOMAIN,
        resource: ref.current,
        username: getRes?.data?.jid,
        password: getRes?.data?.jpass,
      });

      debug(xmpp);

      xmpp.on("offline", () => {});

      xmpp.on("online", async (jid) => {
        await xmpp.send(xml("presence"));
        if (url[1] === "chat") {
          getUserchatCommunication(url[2], xmpp); // first time
        } else {
          getGroupchatCommunication(url[2], xmpp); // first time for group
        }
      });

      //To receive the messages
      xmpp.on("stanza", async (stanza) => {
        let currentUrl = window.location.pathname.split("/");
        if (currentUrl[1] === "chat") {
          if (stanza.is("message")) {
            let getReceivestanza = stanza.getChild("archived");
            if (getReceivestanza?.name === "archived") {
              getReceivedmessage(stanza);
            } else {
              getChathistory(getRes?.data?.jid, stanza);
            }
          }

          if (stanza.is("iq")) {
            let value = stanza?.getChild("fin");

            value = value.getChild("set");

            if (value.getChild("count")) {
              value = value.getChild("count");
              if (
                getMessagehistoryarry?.length === Number(value?.children[0])
              ) {
                localStorage.setItem(
                  "msglistdata",
                  JSON.stringify(getMessagehistoryarry)
                );
                setShowallmessages(getMessagehistoryarry);
              } else {
                if (!isNaN(Number(value?.children[0]))) {
                  localStorage.setItem(
                    "msglistdata",
                    JSON.stringify(getMessagehistoryarry)
                  );
                  setShowallmessages(getMessagehistoryarry);
                }
              }
            }
          }
        } else {
          // Getting group chat history stanza and received msg
          getGroupchathistory(getRes?.data?.jid, stanza);
        }
      });

      xmpp.on("status", (status) => {
        console.debug("Status", status);
      });

      xmpp.start().then(() => {
        setXmppclient(xmpp);
      });
    }
    // eslint-disable-next-line
  }, []);

  // After browser back

  // eslint-disable-next-line
  useEffect(() => {
    let jid = window.location.pathname.split("/").pop();
    if (currentTab === "direct" && url[1] === "chat") {
      showReceiverchathistory(jid);
    } else {
      localStorage.setItem("grouphistory", "false"); // When we click on other group and also click on back grouphistory should be false
      showReceivergrouphistory(jid);
    }
    // eslint-disable-next-line
  }, [getjidFromurl]);

  // onTabchange

  const currentTabchange = (key) => {
    if (key === "direct") {
      setShowGroups(false);
      setCurrenttab(key);
      getUserlist(accessToken, true);
    } else {
      setShowGroups(true);
      setCurrenttab(key);
      getGrouplist(accessToken, true);
    }
  };

  // Get User list

  const getUserlist = async (access_token, tabchange) => {
    let getList = await getmyChatListapiRequet(access_token);
    let userdetail = {};
    if (getList.status === 200) {
      let getres = await getList.json();
      if (getres?.data?.chat_list?.length > 0) {
        getres?.data?.chat_list.forEach((item) => {
          userdetail = {
            last_msg: item?.last_msg,
            first_name: item?.user_detail?.first_name,
            images: item?.user_detail?.images,
            image_url: item?.user_detail?.image_url,
            jid: item?.user_detail?.jid,
            last_updated_time: item?.last_updated_time,
          };
          getUserdetailarray.push(userdetail);
        });

        if (tabchange === false) {
          // Below code check first time when we navigate from other page to chat module
          const filterUserlist = getUserdetailarray.filter(
            (item) => item.jid === getjidFromurl
          );

          if (filterUserlist.length === 0) {
            // If new Jid is not present then append to list
            let getName = getjidFromurl.split("_");
            userdetail = {
              last_msg: "",
              first_name: getName[0],
              images: null,
              image_url: "",
              jid: getjidFromurl,
              last_updated_time: new Date(),
            };
            getUserdetailarray.unshift(userdetail);
          } else {
            //first remove
            getUserdetailarray = getUserdetailarray.filter(
              (item) => item.jid !== getjidFromurl
            );
            // set to first position
            getUserdetailarray.unshift(filterUserlist[0]);
          }
        }
        setUserinfo(getUserdetailarray);
        setUsernameImage({
          userImage:
            getUserdetailarray[0].images === null
              ? profile_default
              : getUserdetailarray[0].images,
          userName: getUserdetailarray[0].first_name,
        });
      }
    } else if (getList.status === 400) {
      // If user list is empty then add jid from url
      let getName = getjidFromurl.split("_");
      userdetail = {
        last_msg: "",
        first_name: getName[0],
        images: null,
        image_url: "",
        jid: getjidFromurl,
        last_updated_time: new Date(),
      };
      getUserdetailarray.push(userdetail);
      setUserinfo(getUserdetailarray);
      setUsernameImage({
        userImage: profile_default,
        userName: getName[0],
      });
    }

    if (tabchange === true) {
      navigate(`/chat/${accessToken}/${getUserdetailarray[0]?.jid}`);
    }

    localStorage.setItem("userlist", JSON.stringify(getUserdetailarray));
  };

  const getUserchatCommunication = async (access_token, xmpp) => {
    await getReadMsgnotificationapiRequest(
      access_token,
      getUserdetailarray[0]?.jid
    );
    setRecerverjid(getUserdetailarray[0].jid);
    sendqueryforChathistory(xmpp, getUserdetailarray[0]?.jid);
  };

  // Get chat history

  const getChathistory = (jid, stanza) => {
    let getMessage = stanza
      ?.getChild("result")
      ?.getChild("forwarded")
      ?.getChild("message")
      ?.getChild("body");

    let getfromJid = getMessage?.parent?.attrs?.from?.split("/");

    getMessage = getMessage?.children[0];

    let fileType = getMessage.split(".").pop().toLowerCase();

    let messageType;

    if (getfromJid[0] === jid) {
      messageType = "send";
    } else {
      messageType = "receive";
    }

    if (fileType === "png" || fileType === "jpeg" || fileType === "jpg") {
      getValue = {
        message: getMessage,
        type: messageType,
        show: "document",
        source: getMessage,
        default_photo: jpg_icon,
      };
    } else if (fileType === "doc" || fileType === "docx") {
      getValue = {
        message: getMessage,
        type: messageType,
        show: "document",
        source: doc_doc,
        default_photo: doc_doc,
      };
    } else if (fileType === "pdf") {
      getValue = {
        message: getMessage,
        type: messageType,
        show: "document",
        source: pdf_doc,
        default_photo: pdf_doc,
      };
    } else if (fileType === "ppt" || fileType === "pptx") {
      getValue = {
        message: getMessage,
        type: messageType,
        show: "document",
        source: ppt_doc,
        default_photo: ppt_doc,
      };
    } else if (fileType === "xls" || fileType === "xlsx") {
      getValue = {
        message: getMessage,
        type: messageType,
        show: "document",
        source: excel_doc,
        default_photo: excel_doc,
      };
    } else if (fileType === "mp4" || fileType === "mov") {
      getValue = {
        message: getMessage,
        type: messageType,
        show: "video",
        source: "",
        default_photo: "",
      };
    } else {
      getValue = {
        message: getMessage,
        type: messageType,
        show: "text",
        source: "",
        default_photo: "",
      };
    }
    getMessagehistoryarry.push(getValue);
  };

  const showReceiverchathistory = async (jid) => {
    if (accessToken !== "") {
      let filterUserlist = userinfo.filter((item) => item.jid === jid);
      showallmessages.length = 0;
      setShowallmessages([]);
      setUsernameImage({
        userImage:
          filterUserlist[0]?.images === null
            ? profile_default
            : filterUserlist[0]?.images,
        userName: filterUserlist[0]?.first_name,
      });
      setRecerverjid(jid);
      await getReadMsgnotificationapiRequest(accessToken, jid);
      sendqueryforChathistory(xmppclient, jid);
    }
  };

  // Getting send query for chat history

  const sendqueryforChathistory = async (xmpp, getJid) => {
    let uid = Date.now();
    let message = xml(
      "iq",
      {
        xmlns: "jabber:client",
        type: "set",
        id: uid,
      },
      xml(
        "query",
        { xmlns: "urn:xmpp:mam:2" },
        xml(
          "x",
          { xmlns: "jabber:x:data", type: "submit" },
          xml(
            "field",
            { var: "FORM_TYPE", type: "hidden" },
            xml("value", {}, "urn:xmpp:mam:2")
          ),
          xml(
            "field",
            { var: "start" },
            xml("value", {}, "2021-08-01T00:00:00Z")
          ),
          xml("field", { var: "with" }, xml("value", {}, getJid))
        ),
        xml(
          "set",
          { xmlns: "http://jabber.org/protocol/rsm" },
          xml("max", {}, 1000),
          xml("before")
        )
      )
    );

    xmpp.send(message);
  };

  // Receive message

  const getReceivedmessage = (stanza) => {
    let receiverJid = stanza?.attrs?.from?.split("/");

    let getReceivestanza = stanza?.getChild("body");

    getReceivestanza = getReceivestanza?.children[0];

    let fileType = getReceivestanza?.split(".")?.pop()?.toLowerCase();

    if (fileType === "png" || fileType === "jpeg" || fileType === "jpg") {
      getValue = {
        message: getReceivestanza,
        type: "receive",
        show: "document",
        source: getReceivestanza,
        default_photo: jpg_icon,
      };
    } else if (fileType === "doc" || fileType === "docx") {
      getValue = {
        message: getReceivestanza,
        type: "receive",
        show: "document",
        source: doc_doc,
        default_photo: doc_doc,
      };
    } else if (fileType === "pdf") {
      getValue = {
        message: getReceivestanza,
        type: "receive",
        show: "document",
        source: pdf_doc,
        default_photo: pdf_doc,
      };
    } else if (fileType === "ppt" || fileType === "pptx") {
      getValue = {
        message: getReceivestanza,
        type: "receive",
        show: "document",
        source: ppt_doc,
        default_photo: ppt_doc,
      };
    } else if (fileType === "xls" || fileType === "xlsx") {
      getValue = {
        message: getReceivestanza,
        type: "receive",
        show: "document",
        source: excel_doc,
        default_photo: excel_doc,
      };
    } else if (fileType === "mp4" || fileType === "mov") {
      getValue = {
        message: getReceivestanza,
        type: "receive",
        show: "video",
        source: "",
        default_photo: "",
      };
    } else {
      getValue = {
        message: getReceivestanza,
        type: "receive",
        show: "text",
        source: "",
        default_photo: "",
      };
    }

    let userdetail = {};
    let updatedUserdetail = [];
    let userlistData = localStorage.getItem("userlist");
    userlistData = JSON.parse(userlistData);
    userlistData.forEach((item) => {
      if (item.jid === receiverJid[0]) {
        userdetail = {
          last_msg: getReceivestanza,
          first_name: item?.first_name,
          images: item?.images,
          image_url: item?.image_url,
          jid: item?.jid,
          last_updated_time: item?.last_updated_time,
        };
      } else {
        userdetail = {
          last_msg: item?.last_msg,
          first_name: item?.first_name,
          images: item?.images,
          image_url: item?.image_url,
          jid: item?.jid,
          last_updated_time: item?.last_updated_time,
        };
      }
      updatedUserdetail.push(userdetail);
    });

    setUserinfo(updatedUserdetail);

    localStorage.setItem("userlist", JSON.stringify(updatedUserdetail));

    //when we recive msg and when we click on another user clear previoues msg
    while (getMessagehistoryarry.length) {
      getMessagehistoryarry.pop();
    }

    let retrievedData = localStorage.getItem("msglistdata");

    showallmessages.length = 0;
    setShowallmessages([]);

    retrievedData = JSON.parse(retrievedData);

    retrievedData.push(getValue);

    setShowallmessages(retrievedData);

    localStorage.setItem("msglistdata", JSON.stringify(retrievedData));
  };

  const searchUserinputvaluechange = (e) => {
    setSearchlabel(e.target.value);
  };

  // Navigate to another chat

  const showReceiverchat = async (jid) => {
    //After navigate above useEffect call
    setShowchat(true);
    navigate(`/chat/${accessToken}/${jid}`);
  };

  /** Below functionality for group chat  *****/

  // Below functionality for Group chat group list

  const groupRefresh = (access_token, tabchange) => {
    // Call group list after refresh
    setShowGroups(true);
    setCurrenttab("groups");
    getGrouplist(access_token, tabchange);
  };

  const getGrouplist = async (access_token, tabchange) => {
    let getList = await getGroupChatListapiRequet(access_token);
    let groupdetail = {};
    if (getList.status === 200) {
      let getres = await getList.json();
      console.log("group list", getres);
      if (getres?.data?.chat_list?.length > 0) {
        getres?.data?.chat_list.forEach((item) => {
          groupdetail = {
            last_msg: item?.last_msg,
            first_name: item?.user_detail?.name,
            image: item?.user_detail?.image,
            image_url: item?.user_detail?.image_url,
            id: item?.id,
            jid: item?.user_detail?.jid,
            last_updated_time: item?.last_updated_time,
          };
          getGroupdetailarray.push(groupdetail);
        });

        if (tabchange === false) {
          // Below code check first time when we navigate from other page to chat module and also at the time of refresh
          const filterGrouplist = getGroupdetailarray.filter(
            (item) => item.jid === getjidFromurl
          );

          if (filterGrouplist.length === 0) {
            /*// If new Jid is not present then append to list
            let getName = getjidFromurl.split("_");
            groupdetail = {
              last_msg: "",
              first_name: getName[0],
              images: null,
              image_url: "",
              jid: getjidFromurl,
              last_updated_time: new Date(),
            };
            getGroupdetailarray.unshift(groupdetail); */
          } else {
            //first remove
            getGroupdetailarray = getGroupdetailarray.filter(
              (item) => item.jid !== getjidFromurl
            );
            // set to first position
            getGroupdetailarray.unshift(filterGrouplist[0]);
          }
        }
        setGroupinfo(getGroupdetailarray);
        setGroupNameImage({
          groupImage:
            getGroupdetailarray[0]?.image === null
              ? profile_default
              : getGroupdetailarray[0]?.image,
          groupName: getGroupdetailarray[0]?.first_name,
          groupId: getGroupdetailarray[0]?.id,
        });
      }
    } else if (getList.status === 400) {
    }
    if (tabchange === true) {
      navigate(`/groups/${access_token}/${getGroupdetailarray[0]?.jid}`);
    }
    localStorage.setItem("grouphistory", "false");
    localStorage.setItem("grouplist", JSON.stringify(getGroupdetailarray));
  };

  const getGroupchatCommunication = async (access_token, xmpp) => {
    await getReadMsgnotificationapiRequest(
      access_token,
      getGroupdetailarray[0]?.jid
    );
    sendqueryforGrouphistory(xmpp, getGroupdetailarray[0]?.jid);
  };

  const showReceivergrouphistory = async (groupJid) => {
    if (accessToken !== "") {
      let filterGrouplist = groupinfo.filter((item) => item.jid === groupJid);
      showgroupMessage.length = 0;
      setShowgroupmessage([]);
      setGroupNameImage({
        groupImage:
          filterGrouplist[0]?.image === null
            ? profile_default
            : filterGrouplist[0]?.image,
        groupName: filterGrouplist[0]?.first_name,
        groupId: filterGrouplist[0]?.id,
      });
      await getReadMsgnotificationapiRequest(accessToken, groupJid);
      sendqueryforGrouphistory(xmppclient, groupJid);
    }
  };

  // Group chat history xmpp query

  const sendqueryforGrouphistory = (xmppclient, groupJid) => {
    let loginJid = localStorage.getItem("loginJid");

    let currentLoginid = loginJid.split("@");

    let groupId = groupJid + "/" + currentLoginid[0];

    let message = xml(
      "presence",
      {
        xmlns: "jabber:client",
        to: groupId,
      },
      xml("x", {
        xmlns: "http://jabber.org/protocol/muc",
      })
    );

    xmppclient.send(message);
  };

  // call at the time of group history message get

  const getGroupchathistory = (loginJid, stanza) => {
    let getMessage = stanza?.getChild("body");
    let initailNameofjid = getMessage?.parent?.attrs?.from?.split("/");
    loginJid = loginJid.split("@");

    if (typeof initailNameofjid !== "undefined") {
      let fileType = getMessage?.children[0]?.split(".")?.pop()?.toLowerCase();

      let messageType;

      if (initailNameofjid[1] === loginJid[0]) {
        messageType = "send";
      } else {
        messageType = "receive";
      }

      if (fileType === "png" || fileType === "jpeg" || fileType === "jpg") {
        getValue = {
          message: getMessage?.children[0],
          type: messageType,
          show: "document",
          source: getMessage?.children[0],
          default_photo: jpg_icon,
        };
      } else if (fileType === "doc" || fileType === "docx") {
        getValue = {
          message: getMessage?.children[0],
          type: messageType,
          show: "document",
          source: doc_doc,
          default_photo: doc_doc,
        };
      } else if (fileType === "pdf") {
        getValue = {
          message: getMessage?.children[0],
          type: messageType,
          show: "document",
          source: pdf_doc,
          default_photo: pdf_doc,
        };
      } else if (fileType === "ppt" || fileType === "pptx") {
        getValue = {
          message: getMessage?.children[0],
          type: messageType,
          show: "document",
          source: ppt_doc,
          default_photo: ppt_doc,
        };
      } else if (fileType === "xls" || fileType === "xlsx") {
        getValue = {
          message: getMessage?.children[0],
          type: messageType,
          show: "document",
          source: excel_doc,
          default_photo: excel_doc,
        };
      } else if (fileType === "mp4" || fileType === "mov") {
        getValue = {
          message: getMessage?.children[0],
          type: messageType,
          show: "video",
          source: "",
          default_photo: "",
        };
      } else {
        getValue = {
          message: getMessage?.children[0],
          type: messageType,
          show: "text",
          source: "",
          default_photo: "",
        };
      }
      getGrouphistoryarry.push(getValue);
    }

    if (localStorage.getItem("grouphistory") === "true") {
      receiveMessageongroup(getValue);
    }

    let storeMessage = stanza?.getChild("subject");

    if (storeMessage?.children?.length === 0) {
      // Store total group messages for show
      setShowgroupmessage(getGrouphistoryarry);
      localStorage.setItem("msggroupdata", JSON.stringify(getGrouphistoryarry));
      localStorage.setItem("grouphistory", "true");
    }
  };

  const receiveMessageongroup = (getValue) => {
    let groupJid = window.location.pathname.split("/").pop();
    let groupdetail = {};
    let getGroupdetailarray = [];
    let grouplistData = localStorage.getItem("grouplist");
    grouplistData = JSON.parse(grouplistData);
    grouplistData.forEach((item) => {
      if (item.jid === groupJid) {
        groupdetail = {
          last_msg: getValue?.message,
          first_name: item?.first_name,
          image: item?.image,
          image_url: item?.image_url,
          id: item?.id,
          jid: item?.jid,
          last_updated_time: item?.last_updated_time,
        };
      } else {
        groupdetail = {
          last_msg: item?.last_msg,
          first_name: item?.first_name,
          image: item?.image,
          image_url: item?.image_url,
          id: item?.id,
          jid: item?.jid,
          last_updated_time: item?.last_updated_time,
        };
      }
      getGroupdetailarray.push(groupdetail);
    });

    setGroupinfo(getGroupdetailarray);

    localStorage.setItem("grouplist", JSON.stringify(getGroupdetailarray));

    //when we recive msg and when we click on another user clear previoues msg
    while (getGrouphistoryarry.length) {
      getGrouphistoryarry.pop();
    }

    let retrievedData = localStorage.getItem("msggroupdata");

    showgroupMessage.length = 0;
    setShowgroupmessage([]);

    retrievedData = JSON.parse(retrievedData);

    retrievedData.push(getValue);

    setShowgroupmessage(retrievedData);

    localStorage.setItem("msggroupdata", JSON.stringify(retrievedData));
  };

  // Navigate to another group

  const showCurrentgroup = async (groupJid) => {
    //After navigate above useEffect call
    navigate(`/groups/${accessToken}/${groupJid}`);
  };

  return (
    <div>
      <div className="tab-pane fade show active" id="messages">
        <div className="row">
          <div className="messaging col-sm-12">
            <div className="row inbox_msg">
              <div
                className="col-sm-12 col-md-4 col-lg-4 col-xl-3 inbox_people"
                style={{ display: showChat === false ? "block" : "" }}
              >
                <div className="list_tab">
                  <Tabs
                    activeKey={currentTab}
                    onSelect={(key) => currentTabchange(key)}
                  >
                    <Tab
                      className="direct-tab"
                      eventKey="direct"
                      title="Direct"
                    >
                      <div className="headind_srch">
                        <div className="srch_bar">
                          <div className="stylish-input-group form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <img src={search_icon} alt="sunil" />{" "}
                              </span>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Search for chats & messages"
                                value={searchlabel}
                                onChange={searchUserinputvaluechange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="inbox_chat">
                        {userinfo
                          .filter((label) => {
                            if (searchlabel === "") {
                              return label;
                            }
                            if (
                              label.first_name
                                ?.toLowerCase()
                                .includes(searchlabel?.toLowerCase())
                            ) {
                              return label;
                            }
                            return null;
                          })
                          .map((item) => (
                            <div
                              className="chat_list"
                              onClick={() => showReceiverchat(item?.jid)}
                            >
                              <div className="chat_people">
                                <img
                                  onError={(event) => {
                                    event.target.src = profile_default;
                                  }}
                                  src={
                                    item?.images === null
                                      ? profile_default
                                      : item?.images
                                  }
                                  alt=""
                                />
                                <div className="chat_ib">
                                  <h5>
                                    {item?.first_name}
                                    <span className="chat_date">
                                      {moment(item?.last_updated_time).format(
                                        "DD/MMM/YYYY"
                                      )}
                                    </span>
                                  </h5>
                                  <p>{item?.last_msg}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Tab>
                    <Tab className="group-tab" eventKey="groups" title="Groups">
                      <div className="headind_srch">
                        <div className="srch_bar">
                          <div className="stylish-input-group form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <img src={search_icon} alt="sunil" />{" "}
                              </span>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Search for chats & messages"
                                value={searchlabel}
                                onChange={searchUserinputvaluechange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="inbox_chat">
                        {groupinfo
                          .filter((label) => {
                            if (searchlabel === "") {
                              return label;
                            }
                            if (
                              label.first_name
                                ?.toLowerCase()
                                .includes(searchlabel?.toLowerCase())
                            ) {
                              return label;
                            }
                            return null;
                          })
                          .map((item) => (
                            <div
                              className="chat_list"
                              onClick={() => showCurrentgroup(item?.jid)}
                            >
                              <div className="chat_people">
                                <img
                                  onError={(event) => {
                                    event.target.src = profile_default;
                                  }}
                                  src={
                                    item?.image === null
                                      ? profile_default
                                      : item?.image_url
                                  }
                                  alt=""
                                />
                                <div className="chat_ib">
                                  <h5>
                                    {item?.first_name}
                                    <span className="chat_date">
                                      {moment(item?.last_updated_time).format(
                                        "DD/MMM/YYYY"
                                      )}
                                    </span>
                                  </h5>
                                  <p>{item?.last_msg}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
              {showGroups ? (
                <GroupChat
                  xmppclient={xmppclient}
                  groupnameimg={groupnameimg}
                  accessToken={accessToken}
                  showgroupMessage={showgroupMessage}
                  setShowgroupmessage={setShowgroupmessage}
                  groupinfo={groupinfo}
                  setGroupinfo={setGroupinfo}
                  loginId={loginId}
                  getGrouplist={getGrouplist}
                ></GroupChat>
              ) : (
                <ChatPage
                  xmppclient={xmppclient}
                  usernameimg={usernameimg}
                  accessToken={accessToken}
                  recerverJid={recerverJid}
                  showallmessages={showallmessages}
                  setShowallmessages={setShowallmessages}
                  userinfo={userinfo}
                  setUserinfo={setUserinfo}
                  showChat={showChat}
                  setShowchat={setShowchat}
                ></ChatPage>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageschatPage;
