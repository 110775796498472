export const getGroupChatListapiRequet = async (access_token) => {
  try {
    const headers = new Headers();
    headers.append("Accept", "application/json");

    const callmyChatList = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/groupList?api_token=${access_token}`,
      {
        headers,
        method: "GET",
      }
    );

    return callmyChatList;
  } catch (error) {}
};
