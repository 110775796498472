export const deleteGroupData = async (id, access_token) => {
  try {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    const callGroupdelete = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/groupDelete?id=${id}&api_token=${access_token}`,
      {
        headers,
        method: "GET",
      }
    );
    return callGroupdelete;
  } catch (error) {}
};
