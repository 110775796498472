import React, { useState } from "react";
import "./NewmemberlistmodalPage.css";
import { Modal, Card } from "react-bootstrap";
import profile_default from "../../assets/profile_default.png";
import { getmyChatListapiRequet } from "../util/myChatList";
import { getaddMemberapiRequest } from "../util/addMemberToGroup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function NewmemberlistmodalPage(props) {
  const [newUserlist, setNewuserlist] = useState([]);

  const openList = async () => {
    let getList = await getmyChatListapiRequet(props.accessToken);
    if (getList.status === 200) {
      let getres = await getList.json();
      if (getres?.data?.chat_list?.length > 0) {
        let userList = [];
        let user = {};
        getres?.data?.chat_list.forEach((getitem) => {
          let getUser = props.groupDetail.members.filter(
            (item) => item.id === Number(getitem.id)
          );
          if (getUser.length === 0) {
            user = {
              first_name: getitem?.user_detail?.first_name,
              images: getitem?.user_detail?.images,
              image_url: getitem?.user_detail?.image_url,
              jid: getitem?.user_detail?.jid,
              id: getitem.id,
              is_checked: false,
            };
            userList.push(user);
          }
        });
        setNewuserlist(userList);
      }
    }
  };

  const closeList = () => {
    props.setOpenlist(false);
  };

  const selectUser = (getindex) => {
    let updateList = [];
    newUserlist.forEach((item, index) => {
      if (index === getindex) {
        if (newUserlist[index].is_checked === true) {
          newUserlist[index].is_checked = false;
          updateList.push(newUserlist[index]);
        } else {
          newUserlist[index].is_checked = true;
          updateList.push(newUserlist[index]);
        }
      } else {
        updateList.push(newUserlist[index]);
      }
    });
    setNewuserlist(updateList);
  };

  const newMembersubmit = async () => {
    let selectedId = newUserlist.filter((item) => item.is_checked === true);

    if (selectedId.length !== 0) {
      let group_jid = window.location.pathname.split("/").pop();
      let ids = "";
      ids = selectedId.map((item) => item.id).join(",");

      let getaddMemberapiResponse = await getaddMemberapiRequest(
        group_jid,
        ids
      );

      if (getaddMemberapiResponse.status === 200) {
        toast.success("New members added successfully");
        props.setShowgroupdetail(false);
        props.setOpenlist(false);
      }
    }
  };

  return (
    <Modal
      show={props.openlist}
      onShow={() => openList()}
      onHide={() => closeList()}
      centered
      className="new-list"
    >
      <Modal.Header closeButton>
        <Modal.Title>Select New members</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="user-list">
          {newUserlist.map((item, index) => (
            <div className="user-content" onClick={() => selectUser(index)}>
              <input
                type="checkbox"
                checked={item.is_checked}
                name={item.jid}
                id={item.id}
              />
              <Card className="splist-card" htmlFor={item.id}>
                <Card.Img src={profile_default} />
                <Card.Body>
                  <div className="sp-info">
                    <label>{item.first_name}</label>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={() => newMembersubmit()}>Submit</button>
      </Modal.Footer>
    </Modal>
  );
}
export default NewmemberlistmodalPage;
