export const removeFromGroup = async (id, group, access_token) => {
  try {
    const headers = new Headers();

    headers.append("Content-Type", "application/json");

    const callLoginapi = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/removeMember?id=${id}&group=${group}&api_token=${access_token}`,
      {
        headers,
        method: "POST",
      }
    );
    return callLoginapi;
  } catch (error) {}
};
