export const getUploadimageapiRequest = async (access_token, getAttachment) => {
  try {
    const headers = new Headers();

    let formData = new FormData();

    formData.append("file", getAttachment);

    headers.append("Accept", "application/json");

    const calluploadImage = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/upload`,
      {
        headers,
        method: "POST",
        body: formData,
      }
    );
    return calluploadImage;
  } catch (error) {}
};
