import "./MessageschatPage.css";
import React, { useState, useEffect } from "react";
import { getUploadimageapiRequest } from "../util/uploadImage";
import { getSendMsgnotificationapiRequest } from "../util/sendMsgNotification";
import { xml } from "@xmpp/client";
import jpg_icon from "../../assets/jpg_icon.svg";
import profile_default from "../../assets/profile_default.png";
import doc_doc from "../../assets/doc_doc.png";
import excel_doc from "../../assets/excel_doc.png";
import pdf_doc from "../../assets/pdf_doc.png";
import ppt_doc from "../../assets/ppt_doc.png";
import $ from "jquery";

function ChatPage(props) {
  const userMessage = {
    sendmessage: "",
  };

  let getValue = {};

  const [messagetext, setMessagetext] = useState(userMessage);

  const {
    xmppclient,
    recerverJid,
    usernameimg,
    setShowallmessages,
    showallmessages,
    userinfo,
    setUserinfo,
    accessToken,
    showChat,
    setShowchat,
  } = props;

  // let getMessagehistoryarry = [...showallmessages];

  useEffect(() => {
    $("#chatdiv").scrollTop($("#chatdiv")[0].scrollHeight);
  }, [showallmessages]);

  const sendMessage = () => {
    if (messagetext.sendmessage !== "") {
      sendMessagetoreceiver(messagetext.sendmessage);
    }
  };

  const getAttachmentfile = async (e) => {
    let getAttachment = document.getElementById("chatattachment").files[0];

    const getUploadResponse = await getUploadimageapiRequest(
      accessToken,
      getAttachment
    );

    if (getUploadResponse.status === 200) {
      let getFile = await getUploadResponse.json();
      sendMessagetoreceiver(getFile?.image_url);
    }

    e.target.value = "";
  };

  const messagesendInputchanged = (e) => {
    setMessagetext({
      ...messagetext,
      [e.target.name]: e.target.value,
    });
  };

  const sendMessagetoreceiver = async (getSendmessage) => {
    const message = xml(
      "message",
      { type: "chat", to: recerverJid },
      xml("body", {}, getSendmessage)
    );

    await xmppclient.send(message);

    // Check send message is it image video or text

    let fileType = getSendmessage.split(".").pop().toLowerCase();

    if (fileType === "png" || fileType === "jpeg" || fileType === "jpg") {
      getValue = {
        message: getSendmessage,
        type: "send",
        show: "document",
        source: getSendmessage,
        default_photo: jpg_icon,
      };
    } else if (fileType === "doc" || fileType === "docx") {
      getValue = {
        message: getSendmessage,
        type: "send",
        show: "document",
        source: doc_doc,
        default_photo: doc_doc,
      };
    } else if (fileType === "pdf") {
      getValue = {
        message: getSendmessage,
        type: "send",
        show: "document",
        source: pdf_doc,
        default_photo: pdf_doc,
      };
    } else if (fileType === "ppt" || fileType === "pptx") {
      getValue = {
        message: getSendmessage,
        type: "send",
        show: "document",
        source: ppt_doc,
        default_photo: ppt_doc,
      };
    } else if (fileType === "xls" || fileType === "xlsx") {
      getValue = {
        message: getSendmessage,
        type: "send",
        show: "document",
        source: excel_doc,
        default_photo: excel_doc,
      };
    } else if (fileType === "mp4" || fileType === "mov") {
      getValue = {
        message: getSendmessage,
        type: "send",
        show: "video",
        source: "",
        default_photo: "",
      };
    } else {
      getValue = {
        message: getSendmessage,
        type: "send",
        show: "text",
        source: "",
        default_photo: "",
      };
    }

    // Append last msg to user list of current receiver

    let userdetail = {};
    let getUserdetailarray = [];
    userinfo.forEach((item) => {
      if (item.jid === recerverJid) {
        userdetail = {
          last_msg: getSendmessage,
          first_name: item?.first_name,
          images: item?.images,
          image_url: item?.image_url,
          jid: item?.jid,
          last_updated_time: item?.last_updated_time,
        };
      } else {
        userdetail = {
          last_msg: item?.last_msg,
          first_name: item?.first_name,
          images: item?.images,
          image_url: item?.image_url,
          jid: item?.jid,
          last_updated_time: item?.last_updated_time,
        };
      }
      getUserdetailarray.push(userdetail);
    });

    setUserinfo(getUserdetailarray);

    localStorage.setItem("userlist", JSON.stringify(getUserdetailarray));

    showallmessages.length = 0;
    setShowallmessages([]);

    let retrievedData = localStorage.getItem("msglistdata");

    retrievedData = JSON.parse(retrievedData);

    retrievedData.push(getValue);

    setShowallmessages(retrievedData);

    localStorage.setItem("msglistdata", JSON.stringify(retrievedData));

    setMessagetext({
      sendmessage: "",
    });

    // After send msg call the sendmsgnotification api

    await getSendMsgnotificationapiRequest(
      recerverJid,
      getSendmessage,
      accessToken
    );
  };

  const goBacktolist = () => {
    setShowchat(false);
  };

  return (
    <div
      class="col-sm-12 col-md-8 col-lg-8 col-xl-9 mesgs"
      style={{ display: showChat === true ? "block" : "" }}
    >
      <div class="contact-profile">
        <div
          className="arrow-value"
          style={{ display: showChat === true ? "" : "none" }}
          onClick={() => goBacktolist()}
        >
          <i class="fa fa-arrow-left" aria-hidden="true" />
        </div>
        <div>
          <img
            src={usernameimg.userImage}
            onError={(event) => {
              event.target.src = profile_default;
            }}
            alt=""
          />
          <p>{usernameimg.userName}</p>
        </div>
      </div>
      <div class="msg_history" id="chatdiv">
        {showallmessages.map((item) =>
          item.type === "receive" ? (
            <>
              <div class="incoming_msg">
                <div class="received_msg">
                  <div class="received_withd_msg">
                    {item.show === "text" ? (
                      <p>{item.message}</p>
                    ) : item.show === "document" ? (
                      <a href onClick={() => window.open(item.message)}>
                        <img
                          onError={(event) => {
                            event.target.src = item.default_photo;
                          }}
                          class="received_img"
                          alt=""
                          src={item.source}
                        ></img>
                      </a>
                    ) : (
                      <video className="received_video" controls>
                        <source src={item.message} type="video/mp4" />
                        <source src={item.message} type="video/ogg" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div class="outgoing_msg">
                <div class="sent_msg">
                  {item.show === "text" ? (
                    <p>{item.message}</p>
                  ) : item.show === "document" ? (
                    <a href onClick={() => window.open(item.message)}>
                      <img
                        class="send_img"
                        alt=""
                        onError={(event) => {
                          event.target.src = item.default_photo;
                        }}
                        src={item.source}
                      ></img>
                    </a>
                  ) : (
                    <video className="send_video" controls>
                      <source src={item.message} type="video/mp4" />
                      <source src={item.message} type="video/ogg" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>
              </div>
            </>
          )
        )}
      </div>
      <div class="type_msg">
        <div class="input_msg_write">
          <input
            type="text"
            class="write_msg"
            value={messagetext.sendmessage}
            name="sendmessage"
            onChange={messagesendInputchanged}
            placeholder="Type a message"
          />
          <button class="msg_send_btn" type="button" onClick={sendMessage}>
            <i class="fa fa-paper-plane" aria-hidden="true"></i>
          </button>
          <input
            type="file"
            accept="image/*,video/*"
            id="chatattachment"
            style={{ display: "none" }}
            onChange={getAttachmentfile}
          />
          <button
            class="btn-attach"
            type="button"
            onClick={() => document.getElementById("chatattachment").click()}
          >
            <i class="fa fa-paperclip" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChatPage;
