export const getLoginapiRequest = async (access_token) => {
  try {
    let payload = {};

    payload = {
      api_token: access_token,
    };

    const headers = new Headers();

    headers.append("Content-Type", "application/json");
    const callLoginapi = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/loginXmpp`,
      {
        headers,
        method: "POST",
        body: JSON.stringify(payload),
      }
    );

    return callLoginapi;
  } catch (error) {}
};
