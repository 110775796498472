export const getSendMsgnotificationapiRequest = async (
  jid,
  msg,
  access_token
) => {
  try {
    const headers = new Headers();

    headers.append("Accept", "application/json");

    const callsendMsgNotification = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/sendMsg?jid=${jid}&msg=${msg}&api_token=${access_token}`,
      {
        headers,
        method: "POST",
      }
    );

    return callsendMsgNotification;
  } catch (error) {}
};
