export const getReadMsgnotificationapiRequest = async (access_token, jid) => {
  try {
    const headers = new Headers();

    headers.append("Accept", "application/json");

    const callreadMsgNotification = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/readMsg?jid=${jid}&api_token=${access_token}`,
      {
        headers,
        method: "POST",
      }
    );

    return callreadMsgNotification;
  } catch (error) {}
};
